import { apiMainQuery } from '../../settings/apollo/api/main/query';
import { transferRates } from '../rates/graphql.graphql';
import { SET_COUNTRIES, UPDATE_FOREX_RATES } from './actionTypes';
import { instantSpeedFromCountryQuery, getOccupationsList, googleReviewsQuery } from './graphql.graphql';
import axios from 'axios';

export const fetchOccupationsList = async () => {
  const result = await apiMainQuery(getOccupationsList);
  const { success, data } = result;
  if (success) {
    const { systemOccupationsList } = data;
    return systemOccupationsList;
  }
}

export const fetchCountriesFromRates = () => async dispatch => {
  const result = await apiMainQuery(transferRates);
  const { success, data } = result;
  if (success) {
    const { TransferRatesOnline } = data;
    dispatch({
      type: SET_COUNTRIES,
      payload: TransferRatesOnline
    });
  }
  return result;
};

export const fetchCountriesRest = () => async dispatch => {
  try {
    const { data } = await axios.get(`${process.env.REACT_APP_API}/public-services/system-countries`);

    dispatch({
      type: SET_COUNTRIES,
      payload: data
    });

    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export const fetchCountriesMulticurrencyRest = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API}/public-services/online-rates-multi-currency`);
    const { data } = response;
    return data ? data.rates : [];
  } catch (error) {
    console.log(error);
    return [];
  }
}

export const instantSpeedFromCountry = (country_id, ben_id) =>
  apiMainQuery(instantSpeedFromCountryQuery, { country_id, ben_id });

export const googleReviews = async () =>
  await apiMainQuery(googleReviewsQuery);

export const setCountries = (payload) => ({
  type: SET_COUNTRIES,
  payload
})

export const updateForexRateDetails = (payload) => ({
  type: UPDATE_FOREX_RATES,
  payload
})
